import React from 'react';

const Stack = props => {
    return (
        <div className="stack" style={{position: "relative"}}>
            <span className="stack-item main" style={{top: '20%', left: '25%', fontSize: '2rem'}}>JAVASCRIPT</span>
            <span className="stack-item" style={{top: '0', left: '0%', fontSize: '1rem'}}>REDUX</span>
            <span className="stack-item main" style={{top: '10%', left: '10%', fontSize: '1.5rem'}}>REACT</span>
            <span className="stack-item" style={{top: '5%', left: '45%', fontSize: '1.3rem'}}>VUE</span>
            <span className="stack-item main" style={{top: '45%', left: '20%', fontSize: '2rem'}}>PHP</span>
            <span className="stack-item" style={{top: '62%', left: '0%', fontSize: '1.5rem'}}>LARAVEL</span>
            <span className="stack-item" style={{top: '40%', left: '5%', fontSize: '1rem'}}>OUZO</span>
            <span className="stack-item main" style={{top: '40%', left: '55%', fontSize: '1.5rem'}}>HTML 5</span>
            <span className="stack-item main" style={{top: '68%', left: '70%', fontSize: '1.5rem'}}>CSS 3</span>
            <span className="stack-item" style={{top: '55%', left: '80%', fontSize: '1rem'}}>SCSS</span>
            <span className="stack-item main" style={{bottom: '0', left: '5%', fontSize: '1.5rem'}}>POSTGRESQL</span>
            <span className="stack-item" style={{bottom: '8%', left: '50%', fontSize: '1rem'}}>WEBPACK</span>
            <span className="stack-item" style={{top: '60%', left: '35%', fontSize: '1.3rem'}}>DOCKER</span>
            <span className="stack-item" style={{top: '75%', left: '25%', fontSize: '1rem'}}>MYSQL</span>
            <span className="stack-item" style={{top: '15%', left: '70%', fontSize: '1rem'}}>GATSBY</span>
            <span className="stack-item" style={{top: '30%', left: '85%', fontSize: '1rem'}}>GIT</span>
        </div>
    );
};

export default Stack;
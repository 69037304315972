import React from 'react';
import PropTypes from 'prop-types';

const PhoneLink = ({phone, children, className, linkId, style}) => {

    return (
        <a id={linkId} href={`tel:${phone}`} className={className} style={style}>
            {children || phone}
        </a>
    );
};

PhoneLink.propTypes = {
    phone: PropTypes.string.isRequired,
    className: PropTypes.string
};

export default PhoneLink;
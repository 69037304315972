import React from "react";
import ExternalLink from "./ExternalLink";
import {useTranslation} from "react-i18next";
import PhoneLink from "./PhoneLink";

const Contact = props => {

    const {t} = useTranslation();

    return (
        <section id="contact">
            <div className="inner">
                <section>
                    <form method="POST" data-netlify="true" data-netlify-honeypot="bot-field" name="contact">
                        <input type="hidden" name="form-name" value="contact"/>
                        <div className="field half first">
                            <label htmlFor="name">{t('form.name')}</label>
                            <input type="text" name="name" id="name" required/>
                        </div>
                        <div className="field half">
                            <label htmlFor="email">{t('form.email')}</label>
                            <input type="text" name="email" id="email" required/>
                        </div>
                        <div className="field">
                            <label htmlFor="message">{t('form.message')}</label>
                            <textarea name="message" id="message" rows="6" required/>
                        </div>
                        <ul className="actions">
                            <li>
                                <input type="submit" value={t('buttons.send')} className="special"/>
                            </li>
                            <li>
                                <input type="reset" value={t('buttons.clear')}/>
                            </li>
                        </ul>
                    </form>
                </section>
                <section className="split">
                    <section>
                        <div className="contact-method">
                            <span className="icon alt fa-envelope"/>
                            <h3>{t('contact.email')}</h3>
                            <span>
                             <ExternalLink href={"mailto:bartlomiejplaza@wp.pl"} label={t('contact.email')}>
                            bartlomiejplaza@wp.pl
                        </ExternalLink>
                        </span>
                        </div>
                    </section>
                    <section>
                        <div className="contact-method">
                            <span className="icon alt fa-phone"/>
                            <h3>{t('contact.phone')}</h3>
                            <PhoneLink phone={'+48 783 620 074'}>
                                +48 783-620-074
                            </PhoneLink>
                        </div>
                    </section>
                    <section>
                        <div className="contact-method">
                            <span className="icon alt fa-home"/>
                            <h3>{t('contact.location.title')}</h3>
                            {t('contact.location.value')}
                        </div>
                    </section>
                </section>
            </div>
        </section>
    )
};

export default Contact;

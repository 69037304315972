import React from "react";
import Layout from "../components/Layout";
import Banner from "../components/Banner";
import Contact from "../components/Contact";
import Stack from "../components/Stack";
import SEO from "../components/SEO";
import {Link, useTranslation} from 'gatsby-plugin-react-i18next';

const HomeIndex = () => {

    const {t} = useTranslation();

    return (
        <Layout>
            <SEO/>
            <Banner title={t('index.hero.title')} href={"#static-sites"}/>
            <div id="main">
                <div id="static-sites">
                    <section className="inner static-sites__content">
                        <header className="major">
                            <h2>{t('static-sites.title')}</h2>
                        </header>
                        <p>{t('static-sites.intro')}</p>
                        <ul className="actions" style={{marginTop: '20px'}}>
                            {/*<li>
                                <Link to="/static_sites" className="button next scrolly">
                                    Dowiedz się więcej
                                </Link>
                            </li>*/}
                            <li>
                                <a href="#contact" className="button next scrolly">
                                    {t('buttons.contact')}
                                </a>
                            </li>
                        </ul>
                    </section>
                    <div className={'static-sites__bridge-wrapper'}>
                        <svg className={'static-sites__bridge'} xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 1440 100">
                            <path fillOpacity="1"
                                  d="M0,96L60,90.7C120,85,240,75,360,69.3C480,64,600,64,720,69.3C840,75,960,85,1080,80C1200,75,1320,53,1380,42.7L1440,32L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"/>
                        </svg>
                    </div>
                </div>
                <section id="about_me" className="inner">
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        alignItems: "center"
                    }}>
                        <div style={{marginRight: '20px'}}>
                            <header className="major">
                                <h2>{t('stack.title')}</h2>
                            </header>
                            <p style={{whiteSpace: "pre-line"}}>
                                {t('stack.body')}
                            </p>
                        </div>
                        <Stack/>
                    </div>
                    <ul className="actions" style={{marginTop: '20px'}}>
                        <li>
                            <a href="#contact" className="button next scrolly">
                                {t('buttons.contact')}
                            </a>
                        </li>
                    </ul>
                </section>
            </div>
            <Contact/>
        </Layout>
    );
}

export default HomeIndex;
